import React, { useState } from "react";
import Menu from "../../components/menu";
import Header from "../../components/header";
import BlankPFP from '../../images/blank-pfp.webp';
import { VscEdit } from 'react-icons/vsc';
import { Link } from 'react-router-dom'

export default function Staff() {
	const [staff] = useState([
		{ name: "Jack", id: 1 },
		{ name: "Samuel", id: 2 },
		{ name: "Robert", id: 3 },
		{ name: "Matt", id: 4 },
		{ name: "Boris", id: 5 },
		{ name: "Sheila", id: 6 },
		{ name: "Josh", id: 7 },
		{ name: "Michael", id: 8 },
	])

	function logID(id) {
		console.log(id)
	}

	const [showSide, setShowSide] = useState(false)

	return (
		<div>
			<Header showSide={showSide} setShowSide={setShowSide} />
			<main className="font-montserrat">
				<div className="flex justify-between">
					<Menu showSide={showSide} />
					<div className="w-9/12 sm:w-10/12 md:w-8/12 lg:w-9/12 max-w-xs sm:max-w-none mx-auto">
						<button className="flex text-white bg-green-700 px-8 py-5 text-xl font-semibold rounded-2xl my-4 mx-auto">Add User</button>
						<div className="col-span-5 grid gap-x-6 md:gap-x-2 w-full sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 ">
							{staff.map((el) => (
								<div className="bg-indigo-800 rounded-xl p-2 m-3">
									<img src={BlankPFP} alt="staff-member" className="h-16 mx-auto rounded-full my-1" />
									<p className="text-center text-white text-md mb-2">{el.name}</p>
									<div className="flex justify-between px-8 sm:px-4 mb-2 w-2/3 mx-auto text-white">
										<Link to={`/staff/staff?id=${el.id}`} className="w-full"><button className="bg-red-500 h-9 w-full rounded-full">
											<VscEdit className="mr-1 mx-auto w-5 inline-block" />Edit
										</button></Link>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</main >
		</div >
	);
}
