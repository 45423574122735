import React, { useState } from "react";
import Menu from '../../components/menu';
import Header from '../../components/header';
import { VscEdit, VscTrash } from 'react-icons/vsc';
import { Link } from 'react-router-dom';

export default function Pupils() {
  const [showSide, setShowSide] = useState(false)

  let queryArray = window.location.search.replace('?', '').split('&');
  let queryParams = {}
  for (let i = 0; i <= queryArray.length - 1; i++) {
    queryParams[queryArray[i].split('=')[0]] = queryArray[i].split('=')[1]
  }

  return (
    <div>
      <Header showSide={showSide} setShowSide={setShowSide} />
      <main className="font-montserrat">
        <div className="sm:flex justify-between">
          <Menu showSide={showSide} />
          <div className="w-10/12 sm:w-11/12 md:w-full md:px-12 mx-auto py-16 grid text-center">
            <p className="text-3xl text-center">{queryParams['id'] || 'No id'}</p>
          </div>
        </div>
      </main>
    </div>
  )
}
