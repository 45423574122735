import React from "react";
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import LandingImage from '../../images/landingImage.webp'
import BlankPFP from '../../images/blank-pfp.webp';
import Laptop from '../../images/laptop.webp';

export default function Home() {
  const { loginWithRedirect, logout, user } = useAuth0();
  return (
    <>
      <nav className="bg-gray-900 font-montserrat md:sticky top-0 z-50">
        <div className="md:px-16 xl:px-32 mx-auto py-4 md:flex justify-between">
          <p className="text-white h-8 mt-1 text-center font-bold text-xl mb-2 md:mb-0">SCHOOLSYSTEMS</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
            {user ? (
              <Link to="/staff/home"><button className="h-10 text-xl text-white font-bold hidden md:block">Dashboard</button></Link>
            ) : <div></div>}
            {!user ? (
              <button onClick={() => loginWithRedirect()} className="h-10 text-xl text-white rounded-xl bg-blue-700 px-4 mx-auto font-bold md:bg-transparent">Login</button>
            ) : <button onClick={() => logout({ returnTo: "http://localhost:3000/" })} className="h-10 text-xl text-white rounded-xl bg-blue-700 px-4 mx-auto font-bold md:bg-transparent">Logout</button>}
          </div>
        </div>
      </nav>
      <main className="font-montserrat">
        <div className="bg-gradient-to-r from-custom2 to-custom1 pt-4 pb-24">
          <div className="w-10/12 mx-auto text-center">
            <h1 className="text-4xl md:text-6xl xl:text-7xl font-bold mb-10 mt-16 text-white">School Management</h1>
            <p className="text-2xl xl:text-3xl font-light mb-10 text-white">Noah Dennis - Portfolio Project</p>
            <div className="md:w-96 mx-auto">
              {!user ? (
                <Link to="demo"><button className="w-36 h-16 bg-blue-700 text-white text-lg font-medium rounded-2xl mx-auto transform hover:scale-110 ease-in-out duration-300 hidden md:inline-block">Start Demo</button></Link>
              ) : <Link to="/staff/home"><button className="w-36 h-16 bg-white text-custom2 text-lg font-medium rounded-2xl mx-auto transform hover:scale-110 ease-in-out duration-300 inline-block">Dashboard</button></Link>}
            </div>
            <img src={Laptop} alt="laptop-screen" className="mx-auto w-96 mt-16" />
          </div>
        </div>
        <div className="pt-12 pb-28">
          <div className="w-8/12 mx-auto">
            <h2 className="text-5xl md:text-6xl mb-12 font-bold text-center">Features</h2>
            <div className="grid">
              <div className="grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-16 mb-10">
                <div className="hidden lg:block my-auto">
                  <img src={LandingImage} alt="product-img-1" className="w-10/12 rounded-3xl z-10 shadow-2xl transform hover:scale-110 ease-in-out duration-500" />
                </div>
                <div>
                  <h3 className="text-4xl 2xl:text-5xl font-semibold">Point 1</h3>
                  <p className="mt-6 2xl:text-xl">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lacus turpis, ullamcorper eget leo ac, ultricies porta eros. Etiam ipsum dui, efficitur at purus vitae, consequat pharetra ante. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-16 mb-10">
                <div>
                  <h3 className="text-4xl 2xl:text-5xl font-semibold">Point 2</h3>
                  <p className="mt-6 2xl:text-xl">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lacus turpis, ullamcorper eget leo ac, ultricies porta eros. Etiam ipsum dui, efficitur at purus vitae, consequat pharetra ante. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
                <div className="hidden lg:block my-auto">
                  <img src={LandingImage} alt="product-img-1" className="w-10/12 rounded-3xl z-10 shadow-2xl transform hover:scale-110 ease-in-out duration-500" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-20 bg-gray-900">
          <div className="grid lg:grid-cols-2 2xl:gap-x-8 w-10/12 mx-auto">
            <div>
              <img src={BlankPFP} alt="person1" className="rounded-full w-24 h-24 mx-auto mb-4" />
              <p className="text-white font-light mb-1 text-lg text-center">Example Person</p>
              <p className="text-white mb-4 font-bold text-xl text-center">Example School</p>
              <p className="text-white px-6 text-center 2xl:text-xl">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus in nulla dolor. Donec euismod mauris a tortor tempus, a iaculis libero dictum. Ut ornare tortor sapien, eu efficitur justo rutrum sit amet."</p>
            </div>
            <div className="hidden lg:block">
              <img src={BlankPFP} alt="person2" className="rounded-full w-24 h-24 mx-auto mb-4" />
              <p className="text-white font-light mb-1 text-lg text-center">Example Person</p>
              <p className="text-white mb-4 font-bold text-xl text-center">Example School</p>
              <p className="text-white px-6 text-center 2xl:text-xl">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus in nulla dolor. Donec euismod mauris a tortor tempus, a iaculis libero dictum. Ut ornare tortor sapien, eu efficitur justo rutrum sit amet."</p>
            </div>
          </div>
        </div>
        <div className="py-16">
          <div className="w-11/12 md:w-7/12 mx-auto">
            <div className="mx-auto p-8">
              <h3 className="text-slate-900 font-semibold text-2xl mb-2 2xl:text-4xl">Example Question</h3>
              <p className="text-slate-900 text-xl mb-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus in nulla dolor. Donec euismod mauris a tortor tempus, a iaculis libero dictum. Ut ornare tortor sapien, eu efficitur justo rutrum sit amet.</p>
              <h3 className="text-slate-900 font-semibold text-2xl mb-2 2xl:text-4xl">Example Question</h3>
              <p className="text-slate-900 text-xl mb-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus in nulla dolor. Donec euismod mauris a tortor tempus, a iaculis libero dictum. Ut ornare tortor sapien, eu efficitur justo rutrum sit amet.</p>
              <h3 className="text-slate-900 font-semibold text-2xl mb-2 2xl:text-4xl">Example Question</h3>
              <p className="text-slate-900 text-xl mb-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus in nulla dolor. Donec euismod mauris a tortor tempus, a iaculis libero dictum. Ut ornare tortor sapien, eu efficitur justo rutrum sit amet.</p>
            </div>
          </div>
        </div>
      </main>
      <footer className="bg-gradient-to-r from-custom2 to-custom1 pt-10 pb-4">
        <div className="w-10/12 mx-auto text-center grid grid-cols-2 md:grid-cols-4 mb-12">
          <div>
            <button className="text-white text-lg block mx-auto p-1 xl:text-xl">Example Link</button>
            <button className="text-white text-lg block mx-auto p-1 xl:text-xl">Example Link</button>
            <button className="text-white text-lg block mx-auto p-1 xl:text-xl">Example Link</button>
          </div>
          <div>
            <button className="text-white text-lg block mx-auto p-1 xl:text-xl">Example Link</button>
            <button className="text-white text-lg block mx-auto p-1 xl:text-xl">Example Link</button>
            <button className="text-white text-lg block mx-auto p-1 xl:text-xl">Example Link</button>
          </div>
          <div>
            <button className="text-white text-lg block mx-auto p-1 xl:text-xl">Example Link</button>
            <button className="text-white text-lg block mx-auto p-1 xl:text-xl">Example Link</button>
            <button className="text-white text-lg block mx-auto p-1 xl:text-xl">Example Link</button>
          </div>
          <div>
            <button className="text-white text-lg block mx-auto p-1 xl:text-xl">Example Link</button>
            <button className="text-white text-lg block mx-auto p-1 xl:text-xl">Example Link</button>
            <button className="text-white text-lg block mx-auto p-1 xl:text-xl">Example Link</button>
          </div>
        </div>
        <p className="text-white h-8 mt-1 text-center font-bold text-xl">SCHOOLSYSTEMS</p>
      </footer>
    </>
  )

}
