import React, { useEffect, useState } from 'react';
import { VscChromeClose, VscMenu } from 'react-icons/vsc';
import { useAuth0 } from '@auth0/auth0-react';
//import jwt_decode from "jwt-decode";

export default function Header({ showSide, setShowSide }) {
    const { user, getIdTokenClaims } = useAuth0();
    const [idToken, setIdToken] = useState("User")

    useEffect(async () => {
        const token = await getIdTokenClaims();
        setIdToken(token)
    }
    )

    return (
        <header className="bg-gray-800 select-none text-white flex">
            {showSide ? (
                <VscChromeClose className="block md:hidden w-8 h-8 my-auto mx-6" onClick={() => setShowSide(false)} />
            ) : <VscMenu className="block md:hidden w-8 h-8 my-auto mx-6" onClick={() => setShowSide(true)} />}
            <div className="w-3/5 md:w-3/4 lg:w-10/12" />
            <div className="invisible sm:visible mt-2 mb-1 mx-auto text-md">
                <p>{user.name}</p>
                <p className="font-light">Admin</p>
            </div>
        </header>
    )
}