import React from 'react'
import { Link } from 'react-router-dom';

export default function Menu({ showSide }) {
    return (
        <>
            {showSide ? (
                <div className="absolute md:hidden lg:static top-15 left-0 bg-gray-900 h-screen p-4 select-none w-full text-center text-white font-medium text-xl" >
                    <Link to="/staff/home" className="block py-4">Home</Link>
                    <Link to="/staff/timetables" className="block py-4">Timetables</Link>
                    <Link to="/staff/registers" className="block py-4">Registers</Link>
                    <Link to="/staff/exam-results" className="block py-4">Exam Results</Link>
                    <Link to="/staff/homework" className="block py-4">Homework</Link>
                    <Link to="/staff/pupils" className="block py-4">Pupils</Link>
                    <Link to="/staff/staff" className="block py-4">Staff</Link>
                </div>
            ) : null}
            <div className="md:static hidden md:block bg-gray-900 h-screen pl-6 xl:pl-8 pr-10 pt-4 select-none text-white font-medium text-xl" >
                <Link to="/staff/home" className="block py-4">Home</Link>
                <Link to="/staff/timetables" className="block py-4">Timetables</Link>
                <Link to="/staff/registers" className="block py-4">Registers</Link>
                <Link to="/staff/exam-results" className="block py-4 truncate">Exam Results</Link>
                <Link to="/staff/homework" className="block py-4">Homework</Link>
                <Link to="/staff/pupils" className="block py-4">Pupils</Link>
                <Link to="/staff/staff" className="block py-4">Staff</Link>
            </div>
        </>
    )
}