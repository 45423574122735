import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import Landing from "./pages/landing/landing";
import Home from "./pages/staff/home";
import Timetables from "./pages/staff/timetables";
import Registers from "./pages/staff/registers";
import ExamResults from "./pages/staff/exam-results";
import Homework from "./pages/staff/homework";
import Pupils from "./pages/staff/pupils";
import Staff from "./pages/staff/staff";
import PupilDetail from "./pages/staff/pupil-detail";

const ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component)} {...args} />
);


ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="crm-managment.eu.auth0.com"
      audience="https://crm-managment.eu.auth0.com/api/v2/"
      clientId="sG18NzYMrwWBrw74Yb90LwYEsmdStGYN"
      redirectUri={window.location.origin}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Landing} />
          <ProtectedRoute path="/staff/home" component={Home} />
          <ProtectedRoute path="/staff/timetables" component={Timetables} />
          <ProtectedRoute path="/staff/registers" component={Registers} />
          <ProtectedRoute path="/staff/exam-results" component={ExamResults} />
          <ProtectedRoute path="/staff/homework" component={Homework} />
          <ProtectedRoute exact path="/staff/pupils" component={Pupils} />
          <ProtectedRoute path="/staff/staff" component={Staff} />
          <ProtectedRoute path="/staff/pupils/pupil" component={PupilDetail} />

        </Switch>
      </Router>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}