import React, { useState } from "react";
import { Link } from "react-router-dom";
import Menu from '../../components/menu';
import Header from '../../components/header';
import { FiUser } from 'react-icons/fi';


export default function Main() {
  const [showSide, setShowSide] = useState(false)
  const [users] = useState([{ name: "Pupils", value: 264, color: "bg-blue-600" }, { name: "Staff", value: 23, color: "bg-red-600" }, { name: "Admins", value: 3, color: "bg-green-600" }])

  return (
    <div className="font-montserrat">
      <Header showSide={showSide} setShowSide={setShowSide} />
      <main>
        <div className="justify-between flex">
          <Menu showSide={showSide} />
          <div className="w-full sm:w-11/12 md:w-10/12 mx-auto">
            <div className="select-none pt-8">
              {/*USER STATS*/}
              <div className="pt-4 pb-6 px-4 grid grid-cols-3 gap-x-2 sm:gap-x-4 lg:gap-x-8 gap-y-6 mx-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl">
                {users.map((el) => (
                  <div className={`w-full rounded-xl py-9 px-2 my-auto flex justify-between grid sm:grid-cols-5 ${el.color}`} key={el.name} >
                    <FiUser className="w-16 h-16 text-white mx-auto col-span-2 hidden sm:block" />
                    <div className="mx-auto col-span-3 text-center sm:text-left">
                      <p className="font-bold text-3xl sm:text-4xl text-white">{el.value}</p>
                      <p className="font-medium text-xl sm:text-2xl text-white">{el.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
