import React, { useState } from "react";
import Menu from '../../components/menu';
import Header from '../../components/header';
import { VscOpenPreview } from 'react-icons/vsc';
import { Link } from 'react-router-dom';

export default function Pupils() {
  const [showSide, setShowSide] = useState(false)
  const [pupils, setPupils] = useState([
    { name_first: "Ben", name_last: "Ben", id: 1, year: 7, class: "7S" },
    { name_first: "Vince", name_last: "Vince", id: 2, year: 11, class: "11K" },
    { name_first: "Moses", name_last: "Moses", id: 3, year: 8, class: "8K" },
    { name_first: "Arlen", name_last: "Arlen", id: 4, year: 11, class: "11S" },
    { name_first: "Mason", name_last: "Mason", id: 5, year: 9, class: "9S" },
    { name_first: "Fredrick", name_last: "Fredrick", id: 6, year: 11, class: "11K" },
    { name_first: "Tyler", name_last: "Tyler", id: 7, year: 10, class: "10K" },
    { name_first: "Grant", name_last: "Grant", id: 8, year: 11, class: "11S" },

  ].sort(PerformSort("year")))

  function PerformSort(sortProp, mode = null) {
    if (mode == null) {
      return function (a, b) {
        if (a[sortProp] > b[sortProp]) {
          return 1;
        } else if (a[sortProp] < b[sortProp]) {
          return -1;
        }
        return 0;
      }
    }

    if (mode === "numeric") {
      return function (as, bs) {
        var a, b, a1, b1, i = 0, n, L,
          rx = /(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g;
        if (as === bs) return 0;
        a = as[sortProp].toLowerCase().match(rx);
        b = bs[sortProp].toLowerCase().match(rx);
        L = a.length;

        while (i < L) {
          if (!b[i]) return 1;
          a1 = a[i]
          b1 = b[i++];
          if (a1 !== b1) {
            n = a1 - b1;
            if (!isNaN(n)) return n;
            return a1 > b1 ? 1 : -1;
          }
        }
        return b[i] ? -1 : 0;
      }

    }
  }


  return (
    <div>
      <Header showSide={showSide} setShowSide={setShowSide} />
      <main className="font-montserrat">
        <div className="sm:flex justify-between">
          <Menu showSide={showSide} />
          <div className="w-10/12 sm:w-11/12 md:w-full md:px-12 mx-auto py-10">
            <div className="pl-2 grid grid-cols-6 sm:grid-cols-7 border-2 w-full mx-auto font-bold text-sm sm:text-lg cursor-pointer">
              <p className="m-2 col-span-2 truncate" onClick={() => setPupils(pupils => [...pupils].sort(PerformSort("name_first")))}>First Name</p>
              <p className="m-2 col-span-2 truncate" onClick={() => setPupils(pupils => [...pupils].sort(PerformSort("name_last")))}>Last Name</p>
              <p className="m-2" onClick={() => setPupils(pupils => [...pupils].sort(PerformSort("year")))}>Year</p>
              <p className="m-2 hidden sm:block" onClick={() => setPupils(pupils => [...pupils].sort(PerformSort("class", "numeric")))}>Class</p>
              <p className="m-2 cursor-default">Edit</p>
            </div>
            {pupils.map((el) => (
              <div className="pl-2 py-2 text-sm sm:text-md grid grid-cols-6 sm:grid-cols-7 border-2 w-full mx-auto" key={el.id}>
                <p className="m-2 col-span-2 truncate">{el.name_first}</p>
                <p className="m-2 col-span-2 truncate">{el.name_last}</p>
                <p className="m-2">{el.year}</p>
                <p className="m-2 hidden sm:block">{el.class}</p>
                <Link to={`/staff/pupils/pupil/?id=${el.id}`} className="my-auto m-2"><button className="rounded-2xl text-blue-800 font-bold h-10">
                  <VscOpenPreview className="mx-auto w-5 inline-block" />Edit
                </button></Link>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  )
}
