import React, { useState } from "react";
import Menu from '../../components/menu'
import Header from '../../components/header'


export default function Homework() {
  const [showSide, setShowSide] = useState(true)
  return (
    <div>
      <Header showSide={showSide} setShowSide={setShowSide} />
      <main className="">
        <div className="flex justify-between">
          <Menu showSide={showSide} />
          <div className="w-10/12 mx-auto px-12">
            {/*MAIN CONTENT*/}
          </div>
        </div>

      </main>
    </div>
  )
}
